<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Sig-Mind
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Esqueceu a senha? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Digite seu email e enviaremos as instruções para resetar sua senha
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Enviar o link de alteração
            </b-button>
          </b-form>
        </validation-observer>
        <div
          v-if="carregando"
          class="text-center"
        >
          <br>
          <b-spinner class="mr-1 spinner-border text-primary" />
        </div>
        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Retornar para o login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner
} from "bootstrap-vue"
import { required, email } from "@validations"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner
  },
  data() {
    return {
      carregando: false,
      userEmail: "",
      // validation
      required,
      email
    }
  },
  methods: {
    async resetarSenha() {
      this.carregando = true
      const resp = await useJwt
        .resetarSenha({
          emailDoUsuario: this.userEmail
        })
        .then(response => response)
        .catch(response => response)

      // console.log(resp)
      const retorno = resp.data
      setTimeout(() => {
        this.carregando = false

        try {
          if (retorno.sucesso) {
            // console.log('Sucesso!')
            // console.log(retorno)
            this.$toast({
              component: ToastificationContent,
              position: "top-center",
              timeout: 3000,
              props: {
                title: "Verifique seu email!",
                icon: "CoffeeIcon",
                variant: "success",
                text:
                  "Foi realizado o envio de uma nova senha para o email informado."
              }
            })
            return
          }
        } catch (error) {
          // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: "top-center",
            timeout: 5000,
            props: {
              title: "O email está correto?",
              icon: "CoffeeIcon",
              variant: "warning",
              text:
                "O email digitado não foi encontrado no sistema. Tente novamente."
            }
          })
        }
      }, 7000)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.resetarSenha()
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
